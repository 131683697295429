'use client'

import CreationInput from '@/components/creation-input'
import Link from '@/components/link'
import { Creation, CreationBase, VideoTag } from '@/types'
import Gallery, { GalleryFilter } from '@/components/gallery'
import TheEnd from '@/components/the-end'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/navigation'
import useAmplitude from '@/hooks/useAmplitude'
import { useAtom, useSetAtom } from 'jotai'
import { activeTemplateIdAtom, creationInputAtom, subscriptionDialogContentAtom } from '@/atoms'
import { getCreationDetailUrl, cls, stopPropagation, whisper } from '@/utils'
import { useInfiniteTopCreations } from '@/hooks/useTopCreations'
import IconChevronRight from '@haiper/icons-svg/icons/outline/chevron-right.svg'
import useAutoCleanCreationDetails from '@/hooks/useAutoCleanCreationDetails'
import useConfig from '@/hooks/useConfig'
import { useInfiniteTagCreations } from '@/hooks/useTagCreations'
import { useCachedSwitches } from '@/hooks/useSwitches'
import Spotlights from '@/components/spotlight'
import CreditUpgradeButton from '@/components/credit-upgrade-button'
import { NO_OUTLINE_STYLE, creationInputTranslateStyle } from '@/constants'
import Logo from '@/public/assets/logobeta-full.svg'
import BadgeNew from '@/components/badges/new'
import useActivePlan from '@/hooks/useActivePlan'
import BadgeUpdate from '@/components/badges/update'
import useCreationModes from '@/hooks/useCreationModes'
import TemplatesExplore from '../../components/templates/explore'
import SocialLinks from '@/components/social-links'
import GlobalContainer from '@/components/global-container'

const specialTags = ['top', 'latest']
const disabledTags = ['latest']

const showSpotlightsInsideCreations = true
// const showSpotlightsInsideCreations = false
const showTemplates = true

export default function Explore() {
  const {
    data: topCreations,
    isValidating: topCreationsLoading,
    hasMore: hasMoreTopCreations,
    loadMore: loadMoreTopCreations,
  } = useInfiniteTopCreations()
  const { data: creationModeOptions } = useCreationModes()

  const [tag, setTag] = useState<string>('')

  const {
    data: tagCreations,
    isValidating: tagCreationsLoading,
    hasMore: hasMoreTagCreations,
    loadMore: loadMoreTagCreations,
  } = useInfiniteTagCreations({ tag })

  const { creations, creationsLoading, hasMore, loadMore } = useMemo(() => {
    const result = tag
      ? {
          creations: tagCreations,
          creationsLoading: tagCreationsLoading,
          hasMore: hasMoreTagCreations,
          loadMore: loadMoreTagCreations,
        }
      : {
          creations: topCreations,
          creationsLoading: topCreationsLoading,
          hasMore: hasMoreTopCreations,
          loadMore: loadMoreTopCreations,
        }

    // filter out private creations (if any)
    result.creations = {
      ...(result.creations as any),
      records: result.creations?.records.filter((e) => e.is_public !== false) ?? [],
    }
    return result
  }, [
    tag,
    topCreations,
    topCreationsLoading,
    tagCreations,
    tagCreationsLoading,
    hasMoreTopCreations,
    hasMoreTagCreations,
    loadMoreTopCreations,
    loadMoreTagCreations,
  ])

  const { data: switches, isValidating: switchesLoading } = useCachedSwitches()

  const showTags = switches?.tags ?? false

  const { data: videoTags } = useConfig<VideoTag[]>(showTags ? 'video-tags' : '')

  const fixedTags: VideoTag[] = useMemo(() => {
    return videoTags?.filter((e) => e.fixed && !disabledTags.includes(e.value)) ?? []
  }, [videoTags])

  const handleFilterGallery = useCallback(
    (filter: GalleryFilter) => {
      setTag(fixedTags.some((e) => e.value === filter.value) && !specialTags.includes(filter.value) ? filter.value : '')
    },
    [fixedTags],
  )

  // clear all cache data
  useAutoCleanCreationDetails()

  const router = useRouter()
  const [{ mode }, setCreationInput] = useAtom(creationInputAtom)

  const { track } = useAmplitude()
  const { data: activePlan, loading: activePlanLoading } = useActivePlan()
  const isFreePlan = (activePlan?.is_free || !activePlan) && !activePlanLoading
  const setSubscriptionDialogContent = useSetAtom(subscriptionDialogContentAtom)

  useEffect(() => {
    track('view:explore')
  }, [track])

  const galleryFilters: Array<{ label: string; value: string }> = useMemo(() => {
    return fixedTags.map((tag) => ({
      label: tag.label,
      value: tag.value,
    }))
  }, [fixedTags])

  const handleGotoDetail = useCallback(
    (item: CreationBase) => {
      const detailUrl = getCreationDetailUrl(item)
      track('click:explore:showcase', { creation_id: item.creation_id })
      router.push(detailUrl)
    },
    [router, track],
  )

  const galleryItems: Creation[] = useMemo(() => {
    const records: Creation[] = creations?.records ?? []
    return records.filter((item) => !item.is_illegal && !item.is_nsfw)
  }, [creations?.records])

  const appsScrollViewPortRef = useRef<HTMLDivElement>(null)
  const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)

  return (
    <div className='size-full overflow-y-auto no-scrollbar' data-testid='explore-page'>
      <GlobalContainer className='static'>
        <div className='flex justify-between items-center gap-4 w-full'>
          <div className='text-heading-4xl text-text font-bold hidden md:flex' aria-label='Explore'>
            Explore
          </div>
          <div className='flex md:hidden justify-start text-text' aria-label='logo'>
            <Link href='/' className={cls('relative', NO_OUTLINE_STYLE)} aria-label='signin logo'>
              <Logo width={176} height={72} />
            </Link>
          </div>
          <div className='flex gap-4 md:gap-8 items-center'>
            <SocialLinks />
            <CreditUpgradeButton showMembership className='md:h-10 px-2' source='explore' />
          </div>
        </div>
      </GlobalContainer>
      <div className='flex flex-col p-4 md:px-16 md:py-20 md:pt-2'>
        <div
          className={cls(
            'mb-4 md:mb-8 w-full max-w-full relative',
            switchesLoading && !switches ? 'hidden' : '',
            'mb-0 md:mb-0',
          )}
          aria-label='creation-modes'
          data-testid='creation-modes'
        >
          <div className='grid grid-cols-2 grid-rows-3 sm:grid-cols-3 sm:grid-rows-2 md:grid-rows-1 4xl:grid-cols-6 gap-4 md:gap-4'>
            {creationModeOptions
              .filter((e) => !e.hidden)
              .map((option, index) => {
                const { Icon, iconClassName } = option
                const needUpgrade = option.membersOnly && isFreePlan

                return (
                  <div
                    key={option.mode}
                    className={cls(
                      'relative cursor-pointer flex items-center bg-white/5 rounded-[16px] p-3 border-2 border-b-4 border-solid border-border hover:border-border-hover hover:bg-surface-hover h-[168px] text-text',
                      'flex-1',
                      mode === option.mode ? 'cursor-default' : '',
                      !option.available ? 'hover:bg-white/5 shadow-none cursor-not-allowed hover:border-border' : '',
                      'h-[78px]',
                    )}
                    aria-label='creation-mode-item'
                    data-testid={`creation-mode-item-${option.mode}`}
                    onClick={(e) => {
                      if (!option.available) return
                      if (needUpgrade) {
                        setSubscriptionDialogContent({
                          message: `Upgrade to Haiper Membership to access the ${option.name} feature.`,
                        })
                        return
                      }

                      setCreationInput((prev) => ({
                        ...prev,
                        creation: undefined,
                        mode: option.mode,
                        expanded: true,
                        focusing: true,
                      }))
                      setActiveTemplateId(null)
                      // scroll current element's left to appsScrollViewPortRef's left if it is out of appsScrollViewPortRef's left or right
                      const viewport = appsScrollViewPortRef.current
                      if (viewport) {
                        const left = e.currentTarget.offsetLeft
                        const right = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth
                        const offset = 50
                        if (left < viewport.scrollLeft) {
                          viewport.scrollBy({
                            left: left - viewport.scrollLeft - offset,
                            behavior: 'smooth',
                          })
                        } else if (right > viewport.scrollLeft + viewport.clientWidth) {
                          viewport.scrollBy({
                            left: right - viewport.scrollLeft - viewport.clientWidth + offset,
                            behavior: 'smooth',
                          })
                        }
                      }
                    }}
                  >
                    <div className={cls('flex gap-1 md:gap-2 items-center justify-start')}>
                      <div className={cls('size-10 rounded-full shrink-0 p-2 text-icon-on-color', iconClassName)}>
                        <Icon className={cls(!option.available || needUpgrade ? 'opacity-50' : '')} />
                      </div>
                      <div className='flex flex-col'>
                        <div className='px-1 flex-1 flex items-center justify-between'>
                          <div
                            className={cls(
                              'text-text text-body-md xl:text-body-lg font-medium tracking-32 leading-4',
                              !option.available ? 'text-text-subdued' : '',
                              needUpgrade ? 'text-text-disabled' : '',
                            )}
                          >
                            {option.name}
                          </div>
                          {option.available ? null : (
                            <div className={cls('text-body-sm text-text-subdued ml-auto tracking-15')}>Coming soon</div>
                          )}
                        </div>
                        {option.membersOnly ? (
                          <div className='flex items-center gap-1 text-body-sm px-1'>
                            <Link
                              href='/membership'
                              className='leading-5 tracking-15 font-bold text-text-interactive'
                              onClick={stopPropagation as any}
                            >
                              Members
                            </Link>
                            <span className=''>only</span>
                          </div>
                        ) : null}
                        {option.newFeature ? (
                          <div className='flex items-center gap-1 text-body-xs md:text-body-sm leading-4 px-1 text-text-interactive font-medium whitespace-nowrap'>
                            <span className=''>{option.newFeature}</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {option.isNew ? <BadgeNew className='absolute top-1 md:top-2 right-1 md:right-2' /> : null}
                    {option.isUpdated ? <BadgeUpdate className='absolute top-1 md:top-2 right-1 md:right-2' /> : null}
                  </div>
                )
              })}
          </div>
        </div>
        {!showSpotlightsInsideCreations && <Spotlights />}
        {showTemplates && <TemplatesExplore className='pt-8' />}
        <div className='size-full mx-auto flex-1 flex flex-col mb-20 pt-8' data-testid='creations'>
          <div className='w-full flex items-center justify-between'>
            <div className='text-heading-2xl font-bold pb-4'>Creations</div>
            <Link
              className='flex text-text no-underline hover:no-underline hover:opacity-80'
              aria-label='Creations'
              href='/creations'
            >
              <span className='text-body-md tracking-15'>Start Creating</span>
              <IconChevronRight className='size-5 text-icon' />
            </Link>
          </div>
          <Gallery
            waterfall
            showSpotlights={showSpotlightsInsideCreations}
            source='explore'
            items={galleryItems}
            loading={creationsLoading}
            filters={galleryFilters}
            defaultFilter={galleryFilters[0]}
            loadMore={() => loadMore?.()}
            onFilter={handleFilterGallery}
            onClick={handleGotoDetail}
          />
          {!creationsLoading && !hasMore && galleryItems?.length > 0 && <TheEnd className='mt-10' />}
        </div>
        <footer
          className={cls(
            'z-[2] w-full transition-all fixed md:absolute bottom-16 md:bottom-0 left-1/2 translate-x-[-50%] rounded-t-[30px] md:rounded-t-[24px] pointer-events-none',
          )}
          style={creationInputTranslateStyle}
        >
          <div className='size-full py-0 md:py-5 rounded-t-[30px] md:rounded-t-[24px]'>
            <CreationInput
              className='w-full items-end'
              toolbarDirection='up'
              onGenerate={(creationId: string) => {
                track('click:creation:generate', { creation_id: creationId })
                router.push('/creations')
              }}
            />
          </div>
        </footer>
      </div>
    </div>
  )
}
